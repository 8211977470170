import React, {Component} from "react";
import {Link} from "react-router-dom";
import {API} from "aws-amplify";
import Headline from "./Headline";
import TableExpandable from './TableExpandable';

export default class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            polls: []
        };
    }

    async componentDidMount() {
        this.updateList();
    }

    updateList = async () => {
        if (!this.props.isAuthenticated) {
            return;
        }

        try {
            const polls = await this.polls();
            this.setState({polls: polls});
        } catch (e) {
            alert(e);
        }

        this.setState({isLoading: false});
    };

    polls() {
        return API.get("stemmedor", "/polls");
    }

    renderMenu() {
        return (
            <div className="row">
                <div className="col-md-4 text-right order-md-2">
                    <Link to={"/polls/new"} className={"btn btn-primary shadow"}>
                        <span className="fad fa-plus-square mr-2"/>Nytt spørsmål
                    </Link>
                </div>
                <div className="col">
                    <Headline title={"Dashboard"} faIcon={"chart-line"}/>
                </div>
            </div>
        )
    }

    refreshList = () => {
        this.updateList();
    };

    renderContent() {
        if (this.state.isLoading) {
            return (
                <div className="content shadow mt-3">
                    <div className="row">
                        <div className="col text-center">
                            <span className={"fas fa-spinner fa-spin"}/>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <TableExpandable data={this.state.polls} refreshList={this.refreshList}/>
        );
    }

    render() {
        return (
            <div className="container-fluid">
                {this.renderMenu()}
                {this.renderContent()}
            </div>
        );
    }
}