import React, {Component} from "react";
import VotesGraph from "./VotesGraph";

export default class TableExpandableRowExpanded extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const item = this.props.item;
        return (
            <tr key={"row-expanded-" + item.itemId}
                className={"table-expandable-row-transition " + (item.hideRowTransition ? 'hide-row-transition' : '')}>
                <td colSpan="7">
                    <VotesGraph item={item}/>
                </td>
            </tr>
        );
    }
}