import React, {Component} from "react";
import {Pie} from 'react-chartjs-2';
import {API} from "aws-amplify";

export default class VotesGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            votes: []
        };
    }

    async componentDidMount() {
        try {
            const votes = await this.votes();
            this.setState({votes: votes});
        } catch (e) {
            alert(e);
        }

        this.setState({isLoading: false});
    }

    votes() {
        return API.get("stemmedor", `/polls/${this.props.item.itemId}/votes`);
    }

    getData(votes) {
        console.log(votes);
        console.log(this.props.item);
        let door1 = 0;
        let door2 = 0;
        votes.forEach(vote => {
           if (vote.itemVote === "door1") {
               door1++;
           } else {
               door2++;
           }
        });

        return {
            labels: [
                this.props.item.pollAlternative1,
                this.props.item.pollAlternative2
            ],
            datasets: [{
                data: [door1, door2],
                backgroundColor: [
                    '#FF6384',
                    '#36A2EB'
                ],
                hoverBackgroundColor: [
                    '#FF6384',
                    '#36A2EB'
                ]
            }]
        };
    }

    render() {
        if (this.state.isLoading) {
            return (
                <span className={"fas fa-spinner fa-spin"}/>
            );
        }

        if (this.state.votes.length > 0) {
            return (
                <div className="row">
                    <div className="col-6">
                        <Pie data={this.getData(this.state.votes)}/>
                    </div>
                </div>
            );
        }

        return (
            <div className="row">
                <div className="col">
                    <p>Ingen stemmer registrert.</p>
                </div>
            </div>
        );
    }
}