import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import Routes from "./Routes";
import {Auth} from "aws-amplify";
import "./App.css";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            isAuthenticating: true,
            userEmail: ''
        };
    }

    async componentDidMount() {
        try {
            const user = await Auth.currentSession();
            this.userHasAuthenticated(true, user);
        }
        catch (e) {
            if (e !== 'No current user') {
                alert(e);
            }
        }

        this.setState({isAuthenticating: false});
    }

    userHasAuthenticated = (authenticated, user) => {
        let userEmail = '';
        if (user && user.idToken && user.idToken.payload) {
            userEmail = user.idToken.payload.email;
        } else if (user && user.attributes) {
            userEmail = user.attributes.email;
        }
        this.setState({isAuthenticated: authenticated, userEmail: userEmail});
    };

    handleLogout = async event => {
        await Auth.signOut();

        this.userHasAuthenticated(false, null);

        this.props.history.push("/login");
    };

    renderUserInfo() {
        return (
            <li className="nav-item d-none d-md-block">
                <span className="navbar-text">
                {this.state.userEmail}
                </span>
            </li>
        )
    }

    renderVersionNumber() {
        let content = "v0.42";
        return (
            <div className={"ml-2"}>
                <ul className="navbar-nav">
                    <li className="nav-item d-none d-md-block">
                        <span className="navbar-text">{content}</span>
                    </li>
                </ul>
            </div>
        );
    }

    renderNavbar() {
        return (
            <nav className="navbar sticky-top navbar-expand-lg navbar-dark bg-dark">
                <a className="navbar-brand d-none d-lg-block" href="/">Stemmedør</a>
                <a className="navbar-brand d-lg-none" href="/">Stemmedør</a>
                <div className={"mr-auto"}/>
                {this.state.isAuthenticated &&
                <div>
                    <ul className="navbar-nav">
                        {this.renderUserInfo()}
                    </ul>
                </div>
                }
                {this.renderVersionNumber()}
                <div>
                    {this.state.isAuthenticated &&
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <button className="btn btn-outline-secondary ml-2" onClick={this.handleLogout}>
                                <span className="fad fa-sign-out-alt mr-2"/>Logg ut
                            </button>
                        </li>
                    </ul>
                    }
                </div>
            </nav>
        );
    }

    render() {
        const childProps = {
            isAuthenticated: this.state.isAuthenticated,
            userHasAuthenticated: this.userHasAuthenticated,
            userEmail: this.state.userEmail
        };
        return (
            !this.state.isAuthenticating &&
            <div>
                <div className="container-fluid" style={{paddingLeft: 0, paddingRight: 0}}>
                    {this.renderNavbar()}
                </div>
                <div className="container-fluid mt-3">
                    <div className="row mt-2">
                        <div className="col">
                            <Routes childProps={childProps}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(App);

