import React from "react";
import {Route, Switch} from "react-router-dom";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Home from "./containers/Home";
import Login from "./containers/Login";
import NewPoll from "./containers/NewPoll";
import NotFound from "./containers/NotFound";

export default ({childProps}) =>
    <Switch>
        <AuthenticatedRoute path="/" exact component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/login" exact component={Login} props={childProps}/>
        <AuthenticatedRoute path="/polls/new" exact component={NewPoll} props={childProps}/>
        {/* Finally, catch all unmatched routes */}
        <Route component={NotFound}/>
    </Switch>;