import React from "react";

export default () =>
    <div className="NotFound">
        <div className="row justify-content-md-center">
            <div className="col-md-6">
                <div className="content shadow">
                    <div className={"text-center"}>
                        <h3>Beklager, men siden du leter etter finnes ikke!</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>;