import React, {Component} from "react";

export default class Headline extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <h1 className="text-dark"><span className={"fad mr-2 fa-" + this.props.faIcon}/>{this.props.title}</h1>
        );
    }
}
