const test = {
    apiGateway: {
        REGION: "eu-central-1",
        URL: "https://vjtu060k4e.execute-api.eu-central-1.amazonaws.com/prod"
    }
};

const prod = {
    apiGateway: {
        REGION: "eu-central-1",
        URL: "https://vjtu060k4e.execute-api.eu-central-1.amazonaws.com/prod"
    }
};

const config = process.env.REACT_APP_STAGE === 'prod' ? prod : test;

export default {
    // Add common config values here
    cognito: {
        REGION: "eu-central-1",
        USER_POOL_ID: "eu-central-1_3i0TRTtR1",
        APP_CLIENT_ID: "5onp8ourjnjnedn20f2297ubki",
        IDENTITY_POOL_ID: "eu-central-1:e71805f6-f8d7-4e4c-87b8-82d834a08e5f"
    },
    ...config
};