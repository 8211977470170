import React, {Component} from "react";
import LoaderButton from "../components/LoaderButton";
import {Auth} from "aws-amplify";

export default class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            email: "",
            password: ""
        };
    }

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = async event => {
        event.preventDefault();

        this.setState({isLoading: true});

        try {
            let email = this.state.email;
            if (this.state.email === 'mattis@balab.no') {
                email = 'Mattis@balab.no';
            }
            const user = await Auth.signIn(email, this.state.password);
            this.props.userHasAuthenticated(true, user);
        } catch (e) {
            alert(e.message);
            this.setState({isLoading: false});
        }
    };

    render() {
        return (
            <div className="row justify-content-md-center">
                <div className="col-md-4">
                    <div className="Login content shadow">
                        <div className={"text-center"}>
                            <h1><span className={"far mr-2 fa-door-closed fa-2x"}/><span className={"far mr-2 fa-door-closed fa-2x fa-flip-horizontal"}/></h1>
                            <h5><span className={"fad mr-2 fa-sign-in"}/>Logg inn</h5>
                        </div>
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="email">E-post</label>
                                <input type="email" className="form-control" id="email" value={this.state.email}
                                       onChange={this.handleChange}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Passord</label>
                                <input type="password" className="form-control" id="password"
                                       value={this.state.password}
                                       onChange={this.handleChange}/>
                            </div>
                            <div className="btn-group mr-2" role="group">
                                <LoaderButton
                                    block
                                    bsSize="large"
                                    className="btn-block"
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text="Logg inn"
                                    loadingText="Vennligst vent…"
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
