import React, {Component} from "react";
import moment from "moment";
import {API} from "aws-amplify";

export default class TableExpandableRow extends Component {
    handleSubmit = async event => {
        event.preventDefault();

        this.setState({isSaving: true});

        try {
            await this.updateItem();
            console.log("Complete!");
            this.props.refreshList();
        } catch (e) {
            alert(e);
        }
        this.setState({isSaving: false});
    };

    updateItem() {
        return API.put("stemmedor", `/polls/${this.props.item.itemId}/activate`);
    }

    renderButtons() {
        return (
            <td>
                {!this.props.item.pollActive &&
                <button type="button" onClick={this.handleSubmit}
                        className={"mr-2 btn btn-outline-" + (this.props.isExpanded ? 'secondary' : 'primary')}>
                    Aktiver
                </button>
                }
                <button type="button" onClick={this.props.clickCallback} className={"btn btn-outline-" + (this.props.isExpanded ? 'secondary' : 'primary')}>
                    <span className={"fad fa-" + (this.props.isExpanded ? 'search-minus' : 'search-plus')}/>
                </button>
            </td>
        );
    }

    render() {
        const item = this.props.item;
        return (
            <tr key={"row-data-" + item.itemId} className={"table-expandable-row table-expandable-row-transition " + (item.hideRowTransition ? 'hide-row-transition' : '')}>
                <td>{moment(item.updatedAt).format("YYYY-MM-DD - HH:mm:ss")}</td>
                <td>{item.pollQuestion}</td>
                <td>{item.pollDescription}</td>
                <td>{item.pollAlternative1}</td>
                <td>{item.pollAlternative2}</td>
                <td>{item.pollActive && <span className={"fad fa-thumbs-up"}/>}</td>
                {this.renderButtons()}
            </tr>
        );
    }
}