import React, {Component} from "react";
import LoaderButton from "../components/LoaderButton";
import Headline from "./Headline";
import {API} from "aws-amplify";

export default class NewPoll extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSaving: null,
            pollQuestion: "",
            pollDescription: "",
            pollAlternative1: "",
            pollAlternative2: ""
        };
    }

    validateForm() {
        return (
            this.state.pollQuestion.length > 0 &&
            this.state.pollAlternative1.length > 0 &&
            this.state.pollAlternative2.length > 0
        );
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = async event => {
        event.preventDefault();

        this.setState({isSaving: true});

        try {
            await this.createItem({
                pollQuestion: this.state.pollQuestion,
                pollDescription: this.state.pollDescription,
                pollAlternative1: this.state.pollAlternative1,
                pollAlternative2: this.state.pollAlternative2
            });
            this.props.history.push("/");
        } catch (e) {
            alert(e);
        }
        this.setState({isSaving: false});
    };

    createItem(item) {
        return API.post("stemmedor", "/polls", {
            body: item
        });
    }

    render() {
        return (
            <div className="container">
                <Headline title={"Nytt spørsmål"} faIcon={"plus"}/>
                <form onSubmit={this.handleSubmit}>
                    <div className="content shadow mt-3">
                        <div className="form-group">
                            <label htmlFor="pollQuestion">Spørsmål:</label>
                            <input type="text" className="form-control" id="pollQuestion"
                                   value={this.state.pollQuestion} autoFocus={true} disabled={this.state.isSaving}
                                   onChange={this.handleChange}/>
                            <small id="pollQuestionHelp" className="form-text text-muted">Eks: Trump vs Putin</small>
                        </div>
                        <div className="form-group">
                            <label htmlFor="pollDescription">Beskrivelse:</label>
                            <input type="text" className="form-control" id="pollDescription"
                                   value={this.state.pollDescription} disabled={this.state.isSaving}
                                   onChange={this.handleChange}/>
                            <small id="pollDescriptionHelp" className="form-text text-muted">En nærmere beskrivelse av spørsmålet, hvis nødvendig.</small>
                        </div>
                        <div className="form-group">
                            <div className="form-row">
                                <div className="col-12 col-md-6">
                                    <label htmlFor="pollAlternative1">Alternativ/Dør 1:</label>
                                    <input type="text" className="form-control" id="pollAlternative1"
                                           value={this.state.pollAlternative1} disabled={this.state.isSaving}
                                           onChange={this.handleChange}/>
                                    <small id="itemTypeHelp" className="form-text text-muted">Eks: Trump</small>
                                </div>
                                <div className="col-12 col-md-6">
                                    <label htmlFor="pollAlternative2">Alternativ/Dør 2:</label>
                                    <input type="text" className="form-control" id="pollAlternative2"
                                           value={this.state.pollAlternative2} disabled={this.state.isSaving}
                                           onChange={this.handleChange}/>
                                    <small id="itemTypeHelp" className="form-text text-muted">Eks: Putin</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content shadow mt-2 mb-5">
                        <div className="btn-group mr-2" role="group">
                            <LoaderButton
                                block
                                bsStyle="primary"
                                bsSize="large"
                                disabled={!this.validateForm()}
                                type="submit"
                                faIcon="save"
                                isLoading={this.state.isSaving}
                                text="Lagre"
                                loadingText="Vennligst vent…"
                            />
                        </div>
                        <div className="btn-group" role="group">
                            <LoaderButton
                                block
                                bsSize="large"
                                className="btn-danger"
                                disabled={this.state.isSaving}
                                text="Avbryt"
                                faIcon="times-octagon"
                                type="button"
                                onClick={() => this.props.history.push("/")}
                            />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
