import React, {Component} from "react";
import TableExpandableRow from "./TableExpandableRow";
import TableExpandableRowExpanded from "./TableExpandableRowExpanded";

export default class TableExpandable extends Component {
    constructor() {
        super();

        this.state = {
            expandedRows: []
        };
    }

    handleRowClick(rowId) {
        const currentExpandedRows = this.state.expandedRows;
        const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

        const newExpandedRows = isRowCurrentlyExpanded ?
            currentExpandedRows.filter(id => id !== rowId) :
            currentExpandedRows.concat(rowId);

        this.setState({expandedRows: newExpandedRows});
    }

    renderItem(item) {
        const clickCallback = () => this.handleRowClick(item.itemId);
        const itemRows = [<TableExpandableRow item={item} refreshList={this.props.refreshList} clickCallback={clickCallback} isExpanded={this.state.expandedRows.includes(item.itemId)}/>];

        if (this.state.expandedRows.includes(item.itemId)) {
            itemRows.push(<TableExpandableRowExpanded key={item.itemId} item={item}
                                                      showHandledButton={this.props.showHandledButton}
                                                      handleUpdateHandledClick={(item) => this.props.removeItemFromList(item)}/>);
        }

        return itemRows;
    }

    render() {
        if (this.props.data.length > 0) {
            let allItemRows = [];

            this.props.data
                .sort((a, b) => {
                    return new Date(a.updatedAt) - new Date(b.updatedAt) || new Date(a.createdAt) - new Date(b.createdAt);
                })
                .forEach(item => {
                    const perItemRows = this.renderItem(item);
                    allItemRows = allItemRows.concat(perItemRows);
                });

            return (
                <div className="content shadow mt-3">
                    <div className="row">
                        <table className="table">
                            <thead>
                            <tr>
                                <th scope="col">Timestamp</th>
                                <th scope="col">Spørsmål</th>
                                <th scope="col">Beskrivelse</th>
                                <th scope="col">Dør 1</th>
                                <th scope="col">Dør 2</th>
                                <th scope="col">Aktiv?</th>
                                <th scope="col"/>
                            </tr>
                            </thead>
                            <tbody>
                            {allItemRows}
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        }
        return (
            <div className="content shadow mt-3">
                <div className="row">
                    <div className="col">
                        <p>Ingen registrerte spørsmål.</p>
                    </div>
                </div>
            </div>
        );
    }
}